import React, { useContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { capitalize, uniqueId, has } from 'lodash';
import moment from 'moment';
// State.
import { useStateValue } from '../../store/state';
import crmClient from '../../services/crmClientService';
import { AppContext } from '../layout/AppLayout';
import { OpenModal } from '../../store/modal/action-creators';
import { modalTypes } from '../../store/modal/action-types';
import ORDER_STATUSES, { formatOrderDate, orderCardTypeIcons } from '../../libs/order/orders';
// Deps
import FMKOrderTotal from './fmk-order-total';
import FMKOrderLineItem from './fmk-order-line-item';
import PagePreloader from '../common/loaders/page-preloader';
import FMKOrderCancel from './fmk-order-cancel';
import AppHidden from '../app/common/app-hidden';
import { redirectKeyPressHandler } from '../../libs/accessibility';
// Assets.
import { orderHistoryFMKDetailPage as locale } from '../../locale/locale.json';
import styles from './fmk-order-history-detail.module.scss';
import closeIcon from '../../images/icons/icon-close-black.svg';

const FMKOrderHistoryDetail = ({ id }) => {
  // State
  const [state, dispatch] = useStateValue();
  const [order, setOrder] = useState();
  const isApp = useContext(AppContext);
  const { CRMCustomerID, access_token } = state.user;
  // Local state
  const [message, setMessage] = useState(false);
  // Order Details
  const {
    CancellationCutoffDateTime,
    PickUp: { StoreName, DateTime: pickUpDatetime } = {},
    OrderDetails: { MainItems = [], IncludedSideDishes = [], ExtraSideDishes = [], AddOns = [] } = {},
    PaymentDetails: { cardType, trnDate } = {},
    Order: { CreatedIn, OrderStatus, BonusPointsEarned, SubTotal, TaxTotal, Total } = {},
  } = order || {};
  const isOrderCancelled = OrderStatus && OrderStatus === ORDER_STATUSES.cancelled;
  const today = new Date();
  const cutoffTime = new Date(CancellationCutoffDateTime);
  const totals = {
    subtotal: SubTotal,
    tax: TaxTotal,
    total: Total,
    points: BonusPointsEarned,
  };
  const itemsCount =
    MainItems.length + IncludedSideDishes.length + ExtraSideDishes.length + AddOns.length;
  const cardIcon = orderCardTypeIcons[cardType] || null; // Map card type abbreviation to icon.
  const displayDate = formatOrderDate(trnDate);

  // Methods
  // Toggle cancel slider.
  const openConfirmationModel = () => {
    // Temporary change functionality to show just a notification message.
    dispatch(
      OpenModal({
        id: id,
        type: modalTypes.SLIDEIN,
        content: <FMKOrderCancel orderId={id}></FMKOrderCancel>,
      }),
    );
  };

  // Watchers
  useEffect(() => {
    if (order !== undefined) {
      const {
        CancellationCutoffDateTime,
        PickUp: { DateTime: pickUpDatetime },
      } = order;
      const pick = new Date(pickUpDatetime);
      const cutoffTime = new Date(CancellationCutoffDateTime);
      setMessage(today.getTime() >= cutoffTime.getTime() && today.getTime() < pick.getTime() && !isOrderCancelled);
    }
  }, [order]);

  useEffect(() => {
    crmClient
      .customerFMKOrderGet(CRMCustomerID, id, access_token)
      .then((order) => {
        setOrder(JSON.parse(order.ResponseBody));
      })
      .catch((error) => {
        setOrder(null);
      });
  }, []);

  // Handle conditional rendering
  if (order === undefined) { // Show loader, while loading.
    return <PagePreloader/>;
  } else if (order === null) { // Order not found through individual order load, ID don't exist at Loft.
    return (
      <div className={styles.orderHistoryDetailBackground}>
        <div className={styles.orderHistoryDetailContainer}>
          <h2>Order not found.</h2>
        </div>
      </div>
    );
  }

  // Render
  return (
    <div>
      {message && <div className={styles.message}>
        <div className={styles.messageContainer}>
          <img
            role="button"
            tabIndex="0"
            src={closeIcon}
            onClick={() => setMessage(false)}
            className={styles.messageClose}
            alt="close"
          />
          <div className={styles.messageList}>
            <span>Please contact Customer Care for assistance at <a href="tel:18009566467">1-800-956-6467</a> to cancel your order.</span>
          </div>
        </div>
      </div>}
      <AppHidden>
        <div className={styles.orderHistoryDetailGoBack}>
          <div>
            <span/>
            <Link to="/account-order-history-groceries">{locale.buttons.goBack}</Link>
          </div>
        </div>
      </AppHidden>
      <div className={styles.orderHistoryDetailContainer} data-app={isApp}>
        <div className={styles.orderHistoryDetailHeader}>
          <ul>
            <li>{displayDate}</li>
            <li>
              {itemsCount} {locale.labels.itemsPurchased}
            </li>
            <li>{capitalize(CreatedIn)}</li>
            {cardIcon && (
              <li>
                <img
                  className={styles.orderHistoryDetailCardIcon}
                  src={cardIcon}
                  alt="card type icon"
                />
              </li>
            )}
          </ul>
          {!isOrderCancelled && today.getTime() < cutoffTime.getTime() && (
            <div
              className={styles.orderCancel}
              tabIndex="0"
              onClick={openConfirmationModel}
              onKeyPress={redirectKeyPressHandler(openConfirmationModel)}
              role="button"
            >
              {locale.buttons.cancel}
            </div>
          )}
        </div>
        <div
          className={styles.orderHistoryDetailContent}
          data-order-cancelled={isOrderCancelled}
        >
          <div className={styles.orderHistoryDetailPickUpInfo}>
            <p>{`${locale.labels.pickUpLocation} ${StoreName}`}</p>
            <p>{`${locale.labels.pickUpDateTime} ${pickUpDatetime}`}</p>
            <p>{`${locale.labels.cancelCutoff} ${CancellationCutoffDateTime}`}</p>
            <hr/>
          </div>
          {MainItems.map((item) => (
            <FMKOrderLineItem key={uniqueId(item.ItemId)} item={item}/>
          ))}
          {IncludedSideDishes.length > 0 && (
            <div>
              <p className={styles.orderHistoryDetailDishesLabel}>
                {locale.labels.includedSideDishes}
              </p>
              {IncludedSideDishes.map((item) => (
                <FMKOrderLineItem key={uniqueId(item.ItemId)} item={item}/>
              ))}
            </div>
          )}
          {ExtraSideDishes.length > 0 && (
            <div>
              <p className={styles.orderHistoryDetailDishesLabel}>
                {locale.labels.extraSideDishes}
              </p>
              {ExtraSideDishes.map((item) => (
                <FMKOrderLineItem key={uniqueId(item.ItemId)} item={item}/>
              ))}
            </div>
          )}
          {AddOns.length > 0 && (
            <div>
              <p className={styles.orderHistoryDetailDishesLabel}>{locale.labels.addons}</p>
              {AddOns.map((item) => (
                <FMKOrderLineItem key={uniqueId(item.ItemId)} item={item}/>
              ))}
            </div>
          )}
        </div>
        <div
          className={styles.orderHistoryDetailTotalsComplete}
          data-order-cancelled={isOrderCancelled}
        >
          <FMKOrderTotal orderTotals={totals} orderStatus={OrderStatus}/>
        </div>
      </div>
    </div>
  );
};

FMKOrderHistoryDetail.defaultProps = {
  id: '',
};

FMKOrderHistoryDetail.propTypes = {
  id: PropTypes.string,
};

export default FMKOrderHistoryDetail;
