// Deps.
import React, { useContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { capitalize } from 'lodash';
import moment from 'moment';
// State
import loftClient from '../../services/loftClientService';
import { useStateValue } from '../../store/state';
import { AppContext } from '../layout/AppLayout';
import ORDER_STATUSES, { formatOrderDate, orderCardTypeIcons } from '../../libs/order/orders';
// Deps
import OrderTotal from '../checkout/order-total';
import OrderLineItem from './order-line-item';
import PagePreloader from '../common/loaders/page-preloader';
import AppHidden from '../app/common/app-hidden';
// Assets.
import { orderHistoryClassesDetailPage as locale } from '../../locale/locale.json';
import styles from './order-history-detail.module.scss';

const OrderHistoryDetail = ({ id }) => {
  // State
  const [state] = useStateValue();
  const isApp = useContext(AppContext);
  const { myAccount, user: { CRMCustomerID, access_token } } = state;
  // Local state
  const orderFound = myAccount.classOrders.items.find((order) => String(order.id) === String(id));
  const [order, setOrder] = useState(orderFound);

  // Watchers
  useEffect(() => {
    if (order === undefined) {
      // When order not available in classOrders state is due user have not loaded
      // order list so load this specific order, for detail display.
      loftClient
        .customerOrderGet(CRMCustomerID, id, access_token)
        .then((order) => {
          setOrder(order);
        })
        .catch((error) => {
          setOrder(null);
        });
    } else {

    }
  }, []);

  // Handle conditional rendering
  if (order === undefined) { // Show loader, while loading from Loft.
    return <PagePreloader/>;
  } else if (order === null) { // Order not found through individual order load, ID don't exist at Loft.
    return (
      <div className={styles.orderHistoryDetailBackground}>
        <div className={styles.orderHistoryDetailContainer}>
          <h2>Order not found.</h2>
        </div>
      </div>
    );
  }

  // Order data
  const { cardType, dateCreated, orderItems, orderStatus, paymentMode, pointsEarned } = order;
  const isRefunded = orderStatus.indexOf(ORDER_STATUSES.refund) !== -1;
  const orderTotalContainerClass = isRefunded
    ? styles.orderHistoryDetailTotalsCancel
    : styles.orderHistoryDetailTotalsComplete;
  const totals = {
    subtotal: order.amount,
    tax: order.hst,
    total: order.totalAmount,
    points: order.pointsEarned,
  };
  const itemsCount = orderItems.length; // Count items in the order.
  const cardIcon = orderCardTypeIcons[cardType] || null; // Map card type abbreviation to icon.
  const displayDate = formatOrderDate(dateCreated);


  // Render
  return (
    <div>
      <AppHidden>
        <div className={styles.orderHistoryDetailGoBack}>
          <div>
            <span/>
            <Link to="/account-order-history-classes">{locale.buttons.goBack}</Link>
          </div>
        </div>
      </AppHidden>
      <div className={styles.orderHistoryDetailContainer} data-app={isApp}>
        <div className={styles.orderHistoryDetailHeader}>
          <ul>
            <li>{displayDate}</li>
            <li>
              {itemsCount} {locale.labels.itemsPurchased}
            </li>
            <li>{capitalize(paymentMode)}</li>
            {cardIcon && (
              <li>
                <img
                  className={styles.orderHistoryDetailCardIcon}
                  src={cardIcon}
                  alt="card type icon"
                />
              </li>
            )}
          </ul>
        </div>
        <div className={styles.orderHistoryDetailContent}>
          {orderItems.map((item) => (
            <OrderLineItem key={item.id} orderId={order.id} item={item}/>
          ))}
        </div>
        <div className={orderTotalContainerClass}>
          <OrderTotal
            items={orderItems}
            totals={totals}
            showPoints={true}
            points={pointsEarned}
            pointsLabel={locale.labels.points}
            totalLabel={locale.labels.total}
          />
        </div>
      </div>
    </div>
  );
};

OrderHistoryDetail.defaultProps = {
  id: '',
};

OrderHistoryDetail.propTypes = {
  id: PropTypes.any.isRequired,
};

export default OrderHistoryDetail;
