// Libs.
import React, {useContext, useState} from 'react';
import * as PropTypes from 'prop-types';
// Components.
import OrderHistoryNavigation from './order-history-navigation';
import OrderHistoryClasses from './order-history-classes';
import OrderHistoryGroceries from './order-history-groceries';
// Assets.
import styles from './order-history.module.scss';
import stylesNav from './order-history-navigation.module.scss';
import {AppContext} from "../layout/AppLayout";

const OrderHistory = ({ type }) => {
  const isApp = useContext(AppContext);
  const [orderHistory, setOrderHistory] = useState(type);

  const handleServicesExpand = () => {
    if (orderHistory === 'classes') {
      setOrderHistory('groceries');
    } else {
      setOrderHistory('classes');
    }
  };

  return (
    <div className={styles.orderHistoryBackground}>
      <div className={styles.orderHistoryContainer}>
        {!isApp && <OrderHistoryNavigation current={type} />}
        {isApp && (<ul className={stylesNav.orderHistoryNavigationList}>
          <li>
            <div
              className={
                orderHistory === 'groceries'
                  ? stylesNav.orderHistoryNavigationLinkActive
                  : stylesNav.orderHistoryNavigationLinkInactive
              }
              onClick={() => {handleServicesExpand(orderHistory)}}
            >
              Groceries
            </div>
          </li>
          <li>
            <div
              className={
                orderHistory === 'classes'
                  ? stylesNav.orderHistoryNavigationLinkActive
                  : stylesNav.orderHistoryNavigationLinkInactive
              }

              onClick={() => {handleServicesExpand(orderHistory)}}
            >
              Classes
            </div>
          </li>
        </ul>)}
        {orderHistory === 'classes' ? <OrderHistoryClasses /> : <OrderHistoryGroceries />}
      </div>
    </div>
  );
};

OrderHistory.defaultProps = {
  type: 'classes',
};

OrderHistory.propTypes = {
  type: PropTypes.string,
};

export default OrderHistory;
