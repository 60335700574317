import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
// State
import { AppContext } from '../layout/AppLayout';
import { displayInteger, displayFraction } from '../../libs/commerce/price-formatter';
import ORDER_STATUSES, { formatOrderDate } from '../../libs/order/orders';
// Deps
import { redirectKeyPressHandler } from '../../libs/accessibility';
import NavigationDrawer, { useDrawer } from '../../hooks/useNavigationDrawer';
import navigate from '../../libs/navigate';
import AppShown from '../app/common/app-shown';
import OrderHistoryDetail from './order-history-detail';
// Assets.
import { orderHistoryClassesPage as locale } from '../../locale/locale.json';
import styles from './order-preview-classes.module.scss';
import infoIcon from '../../../svgs/icons/icon-info.svg';
import pageAliases from '../../data/pageAliases';

const OrderPreviewClasses = ({ order }) => {
  // State
  const isApp = useContext(AppContext);
  // Nav
  const [, navigateDrawer] = useDrawer([]);
  const [drawerStatus] = useDrawer([]);
  const drawerOrder = drawerStatus.includes(order.id + '');
  // Order data
  const {
    id,
    dateCreated,
    totalAmount,
    orderItems,
    pointsEarned,
    tyrCardNumber,
    orderStatus,
  } = order;
  const isRefunded = orderStatus.indexOf(ORDER_STATUSES.refund) !== -1;
  const displayDate = formatOrderDate(dateCreated, {format: 'MMMM D, YYYY'});

  // Methods
  const goToOrderDetail = (e) => {
    if (isApp) {
      navigateDrawer(order.id);
    } else if (typeof window !== 'undefined') {
      navigate(`${pageAliases.accountOrderDetail}${order.id}`);
    }
  };

  // Render
  return (
    <>
      <AppShown>
        <NavigationDrawer header={'Classes history'} drawerStatus={drawerOrder}>
          <OrderHistoryDetail id={order.id}/>
        </NavigationDrawer>
      </AppShown>
      <div
        role="button"
        tabIndex="0"
        onClick={goToOrderDetail}
        onKeyPress={redirectKeyPressHandler(goToOrderDetail)}
        className={styles.orderPreviewClassesContainer}
      >
        <ul>
          <li className={styles.orderPreviewClassesNumber}>
            <span>{locale.labels.orderNumber}</span> <span>{id}</span>
          </li>
          <li className={styles.orderPreviewClassesDate}>
            <span>{locale.labels.orderDate}</span> {displayDate}
          </li>
          <li className={styles.orderTiemPreviewTotalItems}>
            {orderItems.length}{' '}
            {orderItems.length === 1
              ? locale.labels.itemsPurchased
              : locale.labels.itemsPurchasedPlural}
          </li>

          {!isRefunded && (
            <li className={styles.orderPreviewClassesTotalPaid} data-app={isApp}>
              <div className={styles.orderPreviewClassesStatusPaid} data-app={isApp}>
                {locale.labels.statusPaid}
              </div>
              <div className={styles.orderPreviewClassesTotalAmounts}>
                <div>
                  <span>${displayInteger(totalAmount)}</span>
                  <span>{displayFraction(totalAmount)}</span>
                </div>
              </div>
            </li>
          )}

          {isRefunded && (
            <li className={styles.orderPreviewClassesTotalRefund} data-app={isApp}>
              <div className={styles.orderPreviewClassesStatusRefund} data-app={isApp}>
                {locale.labels.statusRefund}
              </div>
              <div className={styles.orderPreviewClassesTotalAmounts}>
                <span>{locale.labels.orderCancel}</span>
                <span>${totalAmount}</span>
              </div>
            </li>
          )}

          {!isRefunded && tyrCardNumber && (
            <li className={styles.orderPreviewClassesPoints}>
              <div>
                {pointsEarned} {locale.labels.pointsEarn}
              </div>
              <div>
                {locale.labels.tyrNo} {tyrCardNumber}
              </div>
            </li>
          )}

          {isRefunded && (
            <li className={styles.orderPreviewClassesStatusWarning}>
              <img src={infoIcon} alt="important information"/>
              <div>{locale.labels.orderRefund}</div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

OrderPreviewClasses.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderPreviewClasses;
