import moment from 'moment';

import cardIconVisa from '../../../svgs/icons/card-type-visa.svg';
import cardIconMasterCard from '../../../svgs/icons/card-type-master.svg';
import cardIconAmex from '../../../svgs/icons/card-type-amex.svg';

export const orderCardTypeIcons = {
  VISA: cardIconVisa,
  MASTERCARD: cardIconMasterCard,
  AMEX: cardIconAmex,
  VI: cardIconVisa,
  MA: cardIconMasterCard,
  AM: cardIconAmex,
};

const ORDER_STATUSES = {
  refund: 'REFUND',
  cancelled: 'Cancelled',
  failed: 'FAILED',
  completed: 'COMPLETED',
};

export const formatOrderDate = (date, options = {}) => {
  const {
    parse = 'MM/DD/YYYY hh:mm A',
    format = 'MMMM D, YYYY - h:mma',
  } = options;

  return moment(date, parse).format(format);
};

export default ORDER_STATUSES;
