// Libs.
import React, {useContext, useEffect, useState} from 'react';
// Components.
import OrderPreviewGroceries from './order-preview-groceries';
// State.
import { useStateValue } from '../../store/state';
import { FetchGroceryHistoryItems } from '../../store/myAccount/actions';
import {
  GroceryHistoryItemsLoaded,
  GroceryHistoryItemsClear,
} from '../../store/myAccount/action-creators';
// Assets.
import { orderHistoryGroceriesPage as locale } from '../../locale/locale.json';
import styles from './order-history-groceries.module.scss';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import PagePreloader from '../common/loaders/page-preloader';
import AddMissingPoints from '../common/add-missing-points/add-missing-points';
import {AppContext} from "../layout/AppLayout";

const OrderHistoryGroceries = () => {
  const itemsPerPage = 10;
  const [state, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);
  const [displayLimit, setDisplayLimit] = useState(itemsPerPage);
  const { groceryOrders } = state.myAccount;
  const { CRMCustomerID, access_token } = state.user;
  let displayOrders = groceryOrders.items.slice(0, displayLimit - 1);
  const isApp = useContext(AppContext);

  const dispatchCallback = (data) => {
    dispatch(GroceryHistoryItemsLoaded(data));
    setLoading(false);
  };

  const handleLoadMore = (e) => {
    setDisplayLimit(displayLimit + itemsPerPage);
  };

  useEffect(() => {
    // Fetch the order list everytime we visit the groceries page so that the order status
    // is updated after cancelation, otherwise it would still say "complete"
    setLoading(true);
    // Clear state.
    dispatch(GroceryHistoryItemsClear());
    // Fetch data from CRM.
    FetchGroceryHistoryItems(CRMCustomerID, access_token, 0, dispatchCallback);
  }, []);

  if (loading) {
    return (
      <div className={styles.orderHistoryGroceriesSection}>
        { !isApp && <h2>{locale.titles.primary}</h2>}
        <div className={styles.orderHistoryGroceriesLoading}>
          <PagePreloader />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.orderHistoryGroceriesSection}>
        { !isApp && <h2>{locale.titles.primary}</h2>}
          <AddMissingPoints buttonClass={styles.orderHistoryGroceriesAddMissingPoints} />
        {groceryOrders.loadItems === 0 && (
          <div className={styles.orderHistoryGroceriesNoResults}>{locale.messages.empty}</div>
        )}
        {groceryOrders.loadItems > 0 && (
          <div className={styles.orderHistoryGroceriesAll}>
            {displayOrders.map((order) => (
              <OrderPreviewGroceries key={order.TransactionNumber} order={order} />
            ))}
          </div>
        )}
        {displayOrders.length < groceryOrders.loadItems && (
          <span
            role="button"
            tabIndex="0"
            className={styles.orderHistoryGroceriesLoadMore}
            onClick={handleLoadMore}
            onKeyPress={redirectKeyPressHandler(handleLoadMore)}
          >
            {locale.buttons.loadMore}
          </span>
        )}
      </div>
    );
  }
};

export default OrderHistoryGroceries;
