import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-date-picker';
// State
import { useStateValue } from '../../../store/state';
import { AddMissingPoints } from '../../../store/customer/actions';
import { CloseModal, OpenModal } from '../../../store/modal/action-creators';
import formValidators from '../../../libs/form/form-validator';
import { crmFriendlyDate } from '../../../libs/form/dates';
// Deps
import FormInput from '../form-input';
import { addNotification } from '../../../libs/notify';
// Assets.
import locale from '../../../locale/locale.json';
import styles from './order-add-missing-points.module.scss';
import receiptImage from '../../../images/receipt_mockup.png';
import CalendarIcon from './calendar-icon';
import AppHidden from '../../app/common/app-hidden';
import AppShown from '../../app/common/app-shown';
import { AppContext } from '../../layout/AppLayout';
import { replacePatternWithComponent } from '../../../libs/localeUtils';
import { Link } from 'gatsby';
import { getCategorizedContent } from '../../../libs/static-content/static-content-utils';
import terms from '../../../data/terms-and-conditions';
import { modalTypes } from '../../../store/modal/action-types';
import AppTermsConditions from '../../app/app-terms-conditions';
import { redirectKeyPressHandler } from '../../../libs/accessibility';
import moment from 'moment';

const OrderAddMissingPoints = () => {
  const [state, dispatch] = useStateValue();
  const app = useContext(AppContext);
  const { user } = state;

  const today = new Date();
  const minSelected = new Date(new Date().setDate(today.getDate() - 30));

  const [transNum, setTransNum] = useState({ value: '', error: false });
  const [transDate, setTransDate] = useState({ value: null, error: false });
  const [receiptAmount, setReceiptAmount] = useState({
    value: '',
    error: false,
  });
  const [errorMessages, setErrorMessages] = useState([]);

  const onDateSelected = (date) => {
    // console.log(date);
    setTransDate({ value: date, error: false });
  };

  const closeModal = () => {
    dispatch(CloseModal());
  };

  // Reset submit error if one of the fields errors.
  useEffect(() => {
    if (transNum.error || transDate.error || receiptAmount.error) {
      setErrorMessages('');
    }
  }, [transNum, transDate, receiptAmount]);

  const onSubmitMissingPoints = () => {
    const transRegex = /^[0-9]+$/;
    const amountRegex = /^\$?[0-9]+(\.[0-9][0-9])?$/;

    setTransNum({ value: transNum.value, error: false, crmErrorMessage: '' });
    setTransDate({ value: transDate.value, error: false, crmErrorMessage: '' });
    setReceiptAmount({
      value: receiptAmount.value,
      error: false,
      crmErrorMessage: '',
    });

    if (transNum.value === '' || !transRegex.test(transNum.value)) {
      setTransNum({ value: transNum.value, error: true });
      return;
    }
    if (!transDate.value || (!!transDate.value && transDate.value > new Date())) {
      setTransDate({ value: transDate.value, error: true });
      return;
    }
    if (receiptAmount.value === '' || !amountRegex.test(receiptAmount.value)) {
      setReceiptAmount({ value: receiptAmount.value, error: true });
      return;
    }
    const receiptDate = crmFriendlyDate(transDate);

    AddMissingPoints(
      user.CRMCustomerID,
      transNum.value,
      receiptDate,
      receiptAmount.value,
      user.access_token
    )
      .then((response) => {
        // console.log(response);
        if (response.ResponseCode === '000') {
          addNotification(locale.addMissingPoints.labels.success);
          setTimeout(() => {
            closeModal();
          }, 400);
        } else if (response.requestStatus < 400) {
          setErrorMessages(locale.addMissingPoints.errors.invalidFormFields);
        } else {
          setErrorMessages(locale.addMissingPoints.errors.errorProcessingRequest);
        }
      })
      .catch((e) => {
        setErrorMessages(locale.addMissingPoints.errors.errorProcessingRequest);
      });
  };

  const elements = getCategorizedContent(terms);
  const modalWindow = () => {
    dispatch(
      OpenModal({
        id: 'term-conditions',
        type: modalTypes.SLIDEIN,
        content: (
          <AppTermsConditions
            title={locale.termsAndConditions.title}
            items={elements}
            numItemsPerLine="1"
            viewAllLink=""
          />
        ),
      })
    );
  };

  // Based on the app, or desktop, either serve the Terms & Conditions modal version or plaain text.
  const pattern = /(Terms and Conditions)/;
  const openModal = replacePatternWithComponent(
    locale.addMissingPoints.labels.footnote,
    pattern,
    <span onClick={modalWindow} onKeyPress={redirectKeyPressHandler(modalWindow)}>
      Terms and Conditions
    </span>
  );
  const footnoteNotice = app ? openModal : locale.addMissingPoints.labels.footnote;

  return (
    <div className={styles.orderAddMissingPointsOverlay}>
      <div className={styles.orderAddMissingPointsContainer} data-app={app}>
        <AppHidden>
          <span onClick={closeModal} className={styles.orderAddMissingPointsClose} />
        </AppHidden>
        <h1 className={styles.orderAddMissingPointsTitle}>
          {locale.addMissingPoints.titles.primary}
        </h1>
        <AppHidden>
          <p className={styles.orderAddMissingPointsSubtitle}>
            {locale.addMissingPoints.labels.subtitle}
          </p>
        </AppHidden>
        <AppShown>
          <p className={styles.orderAddMissingPointsSubtitle}>
            {locale.addMissingPoints.labels.appSubtitle}
          </p>
        </AppShown>
        <AppHidden>
          <img className={styles.orderAddMissingPointsBill} src={receiptImage} alt="receiptImage" />
        </AppHidden>
        <div className={styles.orderAddMissingPointsInputTitle}>
          {locale.addMissingPoints.labels.barcodeNumTitle}
        </div>
        <FormInput
          className={styles.orderAddMissingPointsFormInput}
          placeholder={app && locale.addMissingPoints.labels.barcodeNumTitle}
          name={`transaction-number`}
          value={transNum.value}
          onChange={(e) => formValidators.exists(e.target.value, transNum, setTransNum)}
          error={transNum.error}
          errorMsg={transNum.error && locale.addMissingPoints.labels.transNumError}
        />
        <div className={styles.orderAddMissingPointsInputTitle}>
          {locale.addMissingPoints.labels.transDateTitle}
        </div>
        <div className={styles.orderAddMissingPointsCalendarContainer}>
          {app && <span className={styles.orderAddMissingPointsCalendarPlaceholder}>
            {!transDate.value  || transDate.value === ''
              ? locale.addMissingPoints.labels.selectDate : ""
            }
          </span>}
          <DatePicker
            format={'MM/dd/yy'}
            calendarType="US"
            onChange={onDateSelected}
            calendarClassName={[styles.calendarBody]}
            className={[
              styles.calendarBase,
              transDate.value ? styles.calendarBaseDateSelected : '',
            ]}
            name={`transaction-date`}
            clearIcon={null}
            value={transDate.value}
            minDate={minSelected}
            maxDate={today}
            calendarIcon={<CalendarIcon />}
            monthAriaLabel={locale.calendar.monthAriaLabel}
            dayAriaLabel={locale.calendar.dayAriaLabel}
            yearAriaLabel={locale.calendar.yearAriaLabel}
            nativeInputAriaLabel={locale.calendar.nativeInputAriaLabel}
            calendarAriaLabel={locale.calendar.calendarAriaLabel}
          />
        </div>
        <div className={styles.orderAddMissingPointsDateError}>
          {transDate.error && locale.addMissingPoints.labels.transDateError}
        </div>

        <div className={styles.orderAddMissingPointsInputTitle}>
          {locale.addMissingPoints.labels.receiptAmountTitle}
        </div>

        <FormInput
          className={styles.orderAddMissingPointsFormInput}
          placeholder={app && locale.addMissingPoints.labels.receiptAmountPlaceholder}
          value={receiptAmount.value}
          name={`receipt-amount`}
          onChange={(e) => formValidators.exists(e.target.value, receiptAmount, setReceiptAmount)}
          error={receiptAmount.error}
          errorMsg={receiptAmount.error && locale.addMissingPoints.labels.receiptAmountError}
        />
        <div className={styles.orderAddMissingPointsFormError}>{errorMessages}</div>
        <div className={styles.orderAddMissingPointsActions}>
          <button
            onClick={() => {
              onSubmitMissingPoints();
            }}
            className={styles.orderAddMissingPointsButtonConfirm}
            disabled={false}
          >
            {locale.addMissingPoints.buttons.confirm}
          </button>
          <p className={styles.orderAddMissingPointsFootNote}>{footnoteNotice}</p>
        </div>
      </div>
    </div>
  );
};

export default OrderAddMissingPoints;
