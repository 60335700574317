import { useStaticQuery, graphql } from 'gatsby';
import {drupalTransformer} from '../libs/transform/index'

export const useTermsAndConditionsQuery = () => {
  return drupalTransformer.transformNode(useTermsAndConditionsPage());
};

const useTermsAndConditionsPage = () => {
  return useStaticQuery(graphql`
    query {
      termsAndConditions: configPagesTermsAndConditions {
        title: field_t_c_title
        body: field_terms_and_conditions_body {
          value
        }
      }
    }
  `);
};
