import React, { useContext, useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
// State.
import { useStateValue } from '../../store/state';
import { AppContext } from '../layout/AppLayout';
import { FetchClassHistoryItems } from '../../store/myAccount/actions';
import {
  ClassHistoryItemsClear,
  ClassHistoryItemsLoaded,
} from '../../store/myAccount/action-creators';
import ORDER_STATUSES from '../../libs/order/orders';
// Deps
import OrderPreviewClasses from './order-preview-classes';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import PagePreloader from '../common/loaders/page-preloader';
// Assets.
import { orderHistoryClassesPage as locale } from '../../locale/locale.json';
import styles from './order-history-classes.module.scss';

// Flag to detect the first component mount.
let firstMount = true;

const OrderHistoryClasses = () => {
  const [state, dispatch] = useStateValue();
  const [loading, setLoading] = useState(firstMount);
  const { classOrders } = state.myAccount;
  const { CRMCustomerID, access_token } = state.user;
  const isApp = useContext(AppContext);

  const dispatchCallback = (data) => {
    dispatch(ClassHistoryItemsLoaded(data));
    setLoading(false);
  };

  const handleLoadMore = (e) => {
    const offset = classOrders.loadItems;
    setLoading(true);
    FetchClassHistoryItems(CRMCustomerID, access_token, offset, dispatchCallback);
  };

  useEffect(() => {
    // On first component mount or when empty orders state, clear my account
    // class history data and re-fetch to ensure data is up to date, orders may
    // change state, new ones could be added, and so on, is not safe to persist
    // longer this data.
    if (firstMount || classOrders.loadItems === 0) {
      setLoading(true);
      // Clear state.
      dispatch(ClassHistoryItemsClear());
      // Fetch data from LOFT.
      setTimeout(() => {
        FetchClassHistoryItems(CRMCustomerID, access_token, 0, dispatchCallback);
      }, 2000);
      firstMount = false;
    }
  }, []);

  if (loading) {
    return (
      <div className={styles.orderHistoryClassesSection}>
        {!isApp && <h2>{locale.titles.primary}</h2>}
        <div className={styles.orderHistoryClassesLoading}>
          <PagePreloader/>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.orderHistoryClassesSection}>
        {!isApp && <h2>{locale.titles.primary}</h2>}
        {classOrders.loadItems === 0 && (
          <div className={styles.orderHistoryClassesNoResults}>{locale.messages.empty}</div>
        )}
        {classOrders.loadItems > 0 && (
          <div className={styles.orderHistoryClassesAll}>
            {classOrders.items.map((order) =>
              order.orderStatus !== ORDER_STATUSES.failed &&
              <OrderPreviewClasses key={uniqueId(`order-${order.id}`)} order={order}/>,
            )}
          </div>
        )}
        {classOrders.loadItems < classOrders.totalItems && (
          <span
            role="button"
            tabIndex="0"
            className={styles.orderHistoryClassesLoadMore}
            onClick={handleLoadMore}
            onKeyPress={redirectKeyPressHandler(handleLoadMore)}
          >
            {locale.buttons.loadMore}
          </span>
        )}
      </div>
    );
  }
};

export default OrderHistoryClasses;
