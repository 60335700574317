// Libs.
import React, {useContext, useState} from 'react';
import * as PropTypes from 'prop-types';
// Custom Libs.
import { addNotification } from '../../libs/notify';
// Components.
import ErrorMessages from '../message/error-messages';
// State.
import { useStateValue } from '../../store/state';
import { CloseModal } from '../../store/modal/action-creators';
import { uiBodyScrollable } from '../../store/ui/action-creators';
import { FetchGroceryHistoryItem } from '../../store/myAccount/actions';
// Services.
import loftClient from '../../services/loftClientService';
// Custom Libs.
import { redirectKeyPressHandler } from '../../libs/accessibility';
// Assets.
import { orderHistoryCancelSlider as locale } from '../../locale/locale.json';
import styles from './order-item-cancel.module.scss';
import pageAliases from '../../data/pageAliases.json';
import appAliases from "../../data/appAliases"
import {AppContext} from "../layout/AppLayout";
import navigate from '../../libs/navigate';

const OrderItemCancel = ({ orderId, orderItem }) => {
  const [state, dispatch] = useStateValue();
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const { EmailAddress } = state.customer;
  const { CRMCustomerID, access_token } = state.user;
  const app = useContext(AppContext);
  const closeModal = () => {
    // Set active if callback was provided.
    dispatch(CloseModal());
    dispatch(uiBodyScrollable());
  };

  const backToListing = () => {
    // Set active if callback was provided.
    dispatch(CloseModal());
    dispatch(uiBodyScrollable());
    if (app) {
      navigate(`${appAliases.appAccountProfile}?drawer=transactionHistory_classes`);
    } else {
      navigate(pageAliases.classTransactionList);
    }
  };

  const handleCancelItem = (e) => {
    // Prevent cancel duplicated calls.
    if (loading) {
      return false;
    }

    // Flag cancel is processing.
    setLoading(true);
    loftClient
      .customerOrderClassCancel(orderItem.id, EmailAddress, CRMCustomerID, access_token)
      .then((response) => {
        // Close modal.
        closeModal();
        // Update order state to reflect cancellation.
        FetchGroceryHistoryItem(CRMCustomerID, access_token, orderId, dispatch);
        // On success, send users back to the listing vs when the modal closes.
        if (app) {
          navigate(`${appAliases.appAccountProfile}?drawer=transactionHistory_classes`);
        } else {
          navigate(pageAliases.classTransactionList);
        }

        addNotification(locale.messages.success);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = [error.message];
        setErrorMessages(errorMessage);
        // Remove message after some seconds.
        setTimeout(function() {
          setErrorMessages([]);
        }, 3000);
      });
  };

  return (
    <div className={styles.orderItemCancelOverlay}>
      <div className={styles.orderItemCancelContainer} data-app={app}>
        <span
          role="button"
          tabIndex="0"
          onClick={closeModal}
          onKeyPress={redirectKeyPressHandler(closeModal)}
          className={styles.orderItemCancelClose}
        />
        <h1 className={styles.orderItemCancelTitle}>{locale.titles.primary}</h1>
        <p className={styles.orderItemCancelConfirmMessage}>{locale.messages.confirm}</p>
        <div>
          <h2 className={styles.orderItemCancelClassName}>{orderItem.displayName}</h2>
          <p className={styles.orderItemCancelRefundMessage}>{locale.messages.refund}</p>
        </div>
        <div className={styles.orderItemCancelActions}>
          {errorMessages.length > 0 && <ErrorMessages messages={errorMessages} />}
          <button onClick={handleCancelItem} className={styles.orderItemCancelButtonConfirm}>
            {locale.buttons.confirm}
          </button>
          <button onClick={backToListing} className={styles.orderItemCancelButtonBack}>
            {locale.buttons.back}
          </button>
        </div>
      </div>
    </div>
  );
};

OrderItemCancel.propTypes = {
  orderId: PropTypes.number.isRequired,
  orderItem: PropTypes.object.isRequired,
};

export default OrderItemCancel;
