import React from 'react';
import * as PropTypes from 'prop-types';
// State
import ORDER_STATUSES from '../../libs/order/orders';
// Assets.
import styles from './fmk-order-total.module.scss';
import { orderTotalsFMKDetailPage as locale } from '../../locale/locale.json';

const FMKOrderTotal = ({ orderTotals, orderStatus }) => {
  // Order data
  const { subtotal, tax, total, points } = orderTotals;
  const isOrderCancelled = orderStatus === ORDER_STATUSES.cancelled;

  // Render
  return (
    <div className={styles.orderTotalContainer}>
      <dl>
        {isOrderCancelled && (
          <>
            <div>{locale.labels.cancellationMessage}</div>
            <hr/>
          </>
        )}
        <dt>{locale.labels.subtotal}</dt>
        <dd>${isOrderCancelled ? '0' : subtotal.toFixed(2)}</dd>
        <dt>{locale.labels.hst}</dt>
        <dd>${isOrderCancelled ? '0' : tax.toFixed(2)}</dd>
        <hr/>
        <dt>{locale.labels.total}</dt>
        <dd>${isOrderCancelled ? '0' : total.toFixed(2)}</dd>
        <hr/>
        {points > 0 && (
          <>
            <dt>{locale.labels.bonusPointsEarned}</dt>
            <dd>{isOrderCancelled ? '0' : points}</dd>
          </>
        )}
      </dl>
    </div>
  );
};

FMKOrderTotal.propTypes = {
  orderTotals: PropTypes.object,
  orderStatus: PropTypes.string,
};

export default FMKOrderTotal;
