import React from 'react';
import * as PropTypes from 'prop-types';
import styles from './fmk-order-line-item.module.scss';

const FMKOrderLineItem = ({ item }) => {
    return (
        <div className={styles.orderLineItemContainerComplete}>
            <ul className={styles.orderLineItemRow}>
                <li>
                    <h2>{item.Name}</h2>
                </li>
                <li className={styles.orderLineItemSubtotal}>
                    <span>{item.Price}</span>
                </li>
                <li>
                    <span className={styles.orderLineItemQuantity}>{item.Quantity}</span>
                </li>
            </ul>
            <div className={styles.orderLineItemExtra}>
                <div>
                    <div className={styles.orderLineItemSize}>
                        {item.Size}
                    </div>
                </div>
                {item.Price !== "" && <div>
                    <div className={styles.orderLineItemTotal}>
                        ${item.Total}
                    </div>
                </div>}
            </div>
        </div>
    );
};

FMKOrderLineItem.propTypes = {
    item: PropTypes.object.isRequired,
};

export default FMKOrderLineItem;
