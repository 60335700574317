// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import moment from 'moment';
// Components.
import OrderItemCancel from './order-item-cancel';
// State.
import { OpenModal } from '../../store/modal/action-creators';
import { modalTypes } from '../../store/modal/action-types';
import { useStateValue } from '../../store/state';
// Hooks.
import { useClassesStaticQuery } from '../../hooks/useClassesStaticQuery';
// Assets.
import { orderHistoryClassesDetailPage as locale } from '../../locale/locale.json';
import styles from './order-line-item.module.scss';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import SiteLink from '../common/site-link';
import AppShown from '../app/common/app-shown';
import AppHidden from '../app/common/app-hidden';

const OrderLineItem = ({ orderId, item }) => {
  const [, dispatch] = useStateValue();
  const { classSchedule: classScheduleRel, status } = item;
  const isRefunded = status.indexOf('REFUND') !== -1;
  const itemContainerClassName = isRefunded
    ? styles.orderLineItemContainerCancel
    : styles.orderLineItemContainerComplete;
  const [classScheduleNode] = useClassesStaticQuery(classScheduleRel.id, 'loftId');

  // Determine if the cancel window is still open, 48hs before the classes actual start time.
  const orderItemDate = moment(`${item.startDate} ${item.startTime}`, 'MM/DD/YYYY h:mma');
  // const classCancelWindowOpen = moment(item.cancellationCutoffDate, 'MM/DD/YYYY hh:mm A').subtract(2, 'days').isBefore(moment());
  const classCancelWindowOpen = moment().isBefore(moment(orderItemDate).subtract(2, 'days'));
  // console.log('cancel window', orderItemDate, classCancelWindowOpen);

  // Format class date.
  function formatClassDate(format = 'MMMM D, YYYY') {
    return orderItemDate.format(format);
  }

  // App's format class date.
  const appOrderItemDate = moment(`${item.cancellationCutoffDate}`, 'MM/DD/YYYY h:mma');
  function appFormatClassDate(format = 'MMMM D, h:mma') {
    return appOrderItemDate.format(format);
  }

  // Calculate line item total.
  function calculateItemTotalAmount(unitPrice, quantity) {
    const totalAmount = unitPrice * quantity;
    return totalAmount.toFixed(2);
  }

  // Toggle cancel slider.
  const toggle = () => {
    dispatch(
      OpenModal({
        id: item.id,
        type: modalTypes.SLIDEIN,
        content: <OrderItemCancel orderId={orderId} orderItem={item} />,
      })
    );
  };

  // Unit price is not provided at Loft order so we need to calculate from line
  // item total.
  const calculateUnitPriceFromTotal = (total, quantity) => {
    if (total > 0) {
      return Number(total / quantity).toFixed(2);
    }

    return Number(total).toFixed(2);
  };

  const classUnitPrice = calculateUnitPriceFromTotal(item.amount - item.wineCost, item.participant);
  const wineUnitPrice = calculateUnitPriceFromTotal(item.wineCost, item.wineQuantity);

  return (
    <div className={itemContainerClassName}>
      <ul className={styles.orderLineItemRow}>
        <li>
          {classScheduleNode.alias ? <h2>{item.displayName}</h2> : <h2>{item.displayName}</h2>}
        </li>
        <li className={styles.orderLineItemSubtotal}>
          <span>${classUnitPrice}</span>
        </li>
        <li>
          <span className={styles.orderLineItemQuantity}>{item.participant}</span>
        </li>
      </ul>
      <div className={styles.orderLineItemExtra}>
        <div>
          <div>
            {capitalize(item.store.name)}, {item.store.address}
          </div>
          <div>
            {formatClassDate()} {item.startTime} {locale.labels.timeSeparator} {item.endTime}
          </div>
          <div className={styles.orderLineItemItemMobile}>
            <span className={styles.orderLineItemQuantity}>{item.participant}</span>
            <span>${classUnitPrice}</span>
          </div>
        </div>
        <div>
          <div className={styles.orderLineItemTotal}>
            ${calculateItemTotalAmount(classUnitPrice, item.participant)}
          </div>
          {!isRefunded && classCancelWindowOpen && (
            <div
              role="button"
              tabIndex="0"
              onClick={toggle}
              onKeyPress={redirectKeyPressHandler(toggle)}
              className={styles.orderLineItemCancel}
            >
              {locale.buttons.cancel}
            </div>
          )}
        </div>
      </div>
      {item.withWine && (
        <>
          <ul className={styles.orderLineItemRow}>
            <li>{locale.labels.winePairing}</li>
            <li>${wineUnitPrice}</li>
            <li className={styles.orderLineItemQuantity}>{item.wineQuantity}</li>
          </ul>
          <div className={styles.orderLineItemItemMobile}>
            <span className={styles.orderLineItemQuantity}>{item.wineQuantity}</span>
            <span>${wineUnitPrice}</span>
          </div>
          <div className={styles.orderLineItemExtra}>
            <div className={styles.orderLineItemTotal}>${item.wineCost.toFixed(2)}</div>
          </div>
        </>
      )}
      {isRefunded ? (
        <div className={styles.orderLineItemCancelled}>{locale.messages.cancelled}</div>
      ) : (
        <div className={styles.orderLineItemCutoff}>
          {locale.labels.cancelCutoff} {appFormatClassDate()}
        </div>
      )}
    </div>
  );
};

OrderLineItem.propTypes = {
  orderId: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
};

export default OrderLineItem;
