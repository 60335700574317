// Libs.
import React from 'react';
// Components.
import SEO from '../components/layout/seo';
import MemberLayout from '../components/layout/member-layout';
import OrderHistory from '../components/order-history/order-history';
// Custom Libs.
import { useAuthUserCheckOrError } from '../libs/handleHttpError';

const AccountOrderHistory = ({ location }) => {
  useAuthUserCheckOrError();

  return (
    <MemberLayout containered={true}>
      <SEO title="Transaction History" />
      <OrderHistory type="classes" />
    </MemberLayout>
  );
};

export default AccountOrderHistory;
