// Libs.
import React, {useContext, useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { startsWith } from 'lodash';
// Custom libs.
import { displayInteger, displayFraction } from '../../libs/commerce/price-formatter';
// Assets.
import { orderHistoryGroceriesPage as locale } from '../../locale/locale.json';
import styles from './order-preview-groceries.module.scss';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import infoIcon from '../../images/icons/info.svg';
import navigate from '../../libs/navigate';
import NavigationDrawer from "../../hooks/useNavigationDrawer";
import FMKOrderHistoryDetail from "./fmk-order-history-detail";
import {AppContext} from "../layout/AppLayout";

const OrderPreviewGroceries = ({ order }) => {
  /**
   * TODO: Need to add logic to grey out cancelled orders
   */
  const [hasDetails, setHasDetails] = useState(false);
  const {
    TYRCardNumber,
    TransactionAmount,
    TransactionDate,
    TransactionDiscountAmount,
    TransactionItemsCount,
    TransactionNumber,
    TransactionPointsEarned,
    TransactionStatus,
  } = order;

  const [openDrawer, setOpenDrawer] = useState(false);
  const setOpenDrawerDetails = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    /**
     * TODO: follow up with Jen to see when to show Details for an order.
     */
    if (startsWith(TransactionNumber, 'FMK')) {
      setHasDetails(true);
    }
  }, []);

  // Format the order date.
  function formatOrderDate(date) {
    return moment(date).format('MMMM D, YYYY');
  }

  const isApp = useContext(AppContext);
  const goToOrderDetail = () => {
    if (isApp) {
      setOpenDrawer(TransactionNumber);
    } else {
    navigate(`/account-fmk-order-history/detail/${TransactionNumber}`);
    }
  };
  const transactionStatusStyle =
    TransactionStatus === 'Completed'
      ? styles.orderPreviewGroceriesStatusPaid
      : styles.orderPreviewGroceriesStatusCancelled;
  return (
    <>
      <div
        role={hasDetails ? 'button' : 'false'}
        tabIndex={hasDetails ? '0' : 'false'}
        onClick={() => {
          if (hasDetails) goToOrderDetail();
        }}
        onKeyPress={() => {
          if (hasDetails) redirectKeyPressHandler(goToOrderDetail);
        }}
        className={
          hasDetails
            ? styles.orderPreviewGroceriesClickableContainer
            : styles.orderPreviewGroceriesContainer
        }
      >
        <ul>
          <li
            className={
              hasDetails
                ? styles.orderPreviewGroceriesClickableNumber
                : styles.orderPreviewGroceriesNumber
            }
          >
            <span>{locale.labels.orderNumber}</span> <span>{TransactionNumber}</span>
          </li>
          <li className={styles.orderPreviewGroceriesDate}>
            <span>{locale.labels.orderDate}</span> {formatOrderDate(TransactionDate)}
          </li>
          <li className={styles.orderTiemPreviewTotalItems}>
            {TransactionItemsCount} {locale.labels.itemsPurchased}
          </li>
          <li className={styles.orderPreviewGroceriesTotal} data-app={isApp}>
            <div className={transactionStatusStyle} data-app={isApp}>
              {TransactionStatus === 'Cancelled' ? locale.labels.statusRefunded : TransactionStatus}
            </div>

            {TransactionStatus === 'Completed' && (
              <div className={styles.orderHistoryGroceriesTotalAmounts}>
                {TransactionDiscountAmount > 0 && (
                  <span>
                    ${TransactionDiscountAmount.toFixed(2)} {locale.labels.savedAmount}
                  </span>
                )}
                <div>
                  <span>${displayInteger(TransactionAmount)}</span>
                  <span>{displayFraction(TransactionAmount)}</span>
                </div>
              </div>
            )}
            {TransactionStatus === 'Cancelled' && (
              <div className={styles.orderHistoryGroceriesCancelledAmounts}>
                <span>{locale.labels.cancelledOrder}</span>
                <div className={styles.orderHistoryGroceriesScratchedAmountsWrapp}>
                  <div className={styles.orderHistoryGroceriesScratchedAmounts}></div>
                  <div>
                    <span>${displayInteger(TransactionAmount)}</span>
                    <span>{displayFraction(TransactionAmount)}</span>
                  </div>
                </div>
              </div>
            )}
          </li>
          {TransactionStatus === 'Cancelled' && (
            <div className={transactionStatusStyle} data-disk="false" data-app={isApp}>
              <img src={infoIcon} alt={TransactionStatus} />
              {TransactionStatus}: {locale.labels.statusRefunded}
            </div>
          )}
          {TYRCardNumber && TransactionStatus === 'Completed' && (
            <li className={styles.orderPreviewGroceriesPoints}>
              {TransactionPointsEarned > 0 && (
                <div>
                  {TransactionPointsEarned} {locale.labels.pointsEarn}
                </div>
              )}
              <div>
                {locale.labels.tyrNo} {TYRCardNumber}
              </div>
            </li>
          )}
        </ul>
      </div>
      <NavigationDrawer header={'Groceries history'} drawerStatus={!!openDrawer} onClose={setOpenDrawerDetails}>
        <FMKOrderHistoryDetail id={openDrawer+ ''} />
      </NavigationDrawer>
  </>
  );
};

OrderPreviewGroceries.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderPreviewGroceries;
