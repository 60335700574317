// Libs.
import React from 'react';
import { Link } from 'gatsby';
// Assets.
import styles from './order-history-navigation.module.scss';
import AddMissingPoints from '../common/add-missing-points/add-missing-points';
import AppShown from '../app/common/app-shown';

const OrderHistoryNavigation = () => {
  return (
    <>
      <nav className={styles.orderHistoryNavigationContainer}>
        <ul className={styles.orderHistoryNavigationList}>
          <li>
              <Link
                to="/account-order-history-groceries"
                activeClassName={styles.orderHistoryNavigationLinkActive}
              >
                Groceries
              </Link>
          </li>
          <li>
              <Link
                to="/account-order-history-classes"
                activeClassName={styles.orderHistoryNavigationLinkActive}
              >
                Classes
              </Link>
          </li>
        </ul>
      </nav>
      <AppShown>
        <div className={styles.orderHistoryNavigationAddMissingPointsContainer}>
          <AddMissingPoints buttonClass={styles.orderHistoryNavigationAddMissingPoints} />
        </div>
      </AppShown>
    </>
  );
};

export default OrderHistoryNavigation;
