import React, {useContext} from 'react';
import { uniqueId } from 'lodash';
import styles from './app-terms-conditions.module.scss';

import StaticContentList from '../../components/common/static-content/static-content-list';
import { useStateValue } from '../../store/state';
import { CloseModal } from '../../store/modal/action-creators';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import {AppContext} from "../layout/AppLayout";
import {useTermsAndConditionsQuery} from '../../hooks/useTermsAndConditionsQuery';

const AppTermsConditions = () => {
  // const keys = Object.keys(items);
  const {termsAndConditions:{title, body} = {}} = useTermsAndConditionsQuery();
  const [, dispatch] = useStateValue();
  const isApp = useContext(AppContext);
  const closeModal = () => {
    dispatch(CloseModal());
  };

  return (
    <div className={styles.termsMain} data-app={isApp}>
      <div className={styles.termsBody}>
        <div
          role="button"
          tabIndex="0"
          onClick={closeModal}
          onKeyPress={redirectKeyPressHandler(closeModal)}
          className={styles.termsCloseIcon}
          data-app={isApp}
        />
        <div className={styles.termsTitle}>{title}</div>
        {/* {keys.map((key, index) => (
          <StaticContentList
            listingTitle={items[key][0].headline}
            items={items[key]}
            key={uniqueId('static-list-item')}
            viewAllLink={viewAllLink}
            numItemsPerLine={numItemsPerLine}
          />
        ))} */}
        {body && <div className={styles.staticContentMainBody} dangerouslySetInnerHTML={{__html: body}}/>}
        <div className={styles.termsStickyCloseButton}>
          <button
            onClick={closeModal}
            onKeyPress={redirectKeyPressHandler(closeModal)}
            className={styles.termsCloseButton}
          >
            Close
          </button>
        </div>

      </div>
    </div>
  );
};

export default AppTermsConditions;
