// Custom Libs.
import { addNotification } from '../../libs/notify';
// Services.
import loftClient from '../../services/loftClientService';
import crmService from '../../services/crmClientService';
// State.
import { ClassHistoryItemUpdate } from './action-creators';
export function FetchClassHistoryItems(CRMCustomerID, access_token, offset, dispatchCallback) {
  loftClient
    .customerOrdersGet(CRMCustomerID, access_token, offset)
    .then((data) => {
      dispatchCallback(data);
    })
    .catch((error) => {
      // console.error(error);
      // Fallback to empty response.
      dispatchCallback({ orders: [], total: 0 });
    });
}

export function FetchGroceryHistoryItems(CRMCustomerID, access_token, offset, dispatchCallback) {
  crmService
    .transactionsGet(CRMCustomerID, access_token)
    .then((data) => {
      dispatchCallback(data);
    })
    .catch((error) => {
      // console.error(error);
      // Fallback to empty response.
      dispatchCallback([]);
    });
}

export function FetchGroceryHistoryItem(CRMCustomerID, access_token, id, dispatchCallback) {
  loftClient
    .customerOrderGet(CRMCustomerID, id, access_token)
    .then((item) => {
      dispatchCallback(ClassHistoryItemUpdate(item));
    })
    .catch((error) => {
      addNotification('Something went wrong updating the order detail, please try to refresh the page.');
    });
}
