// Libs
import React, {useContext, useState} from 'react';
// State
import { OpenModal } from '../../../store/modal/action-creators';
import { modalTypes } from '../../../store/modal/action-types';
import { useStateValue } from '../../../store/state';
import { redirectKeyPressHandler } from '../../../libs/accessibility';
// Deps
import OrderAddMissingPoints from './order-add-missing-points';
// Assets
import { orderHistoryGroceriesPage as locale } from '../../../locale/locale';
import {AppContext} from "../../layout/AppLayout";
import styles from "../../customer/modifications/manage-user-points.module.scss";
import NavigationDrawer from "../../../hooks/useNavigationDrawer";
import AppShown from "../../app/common/app-shown";

const AddMissingPoints = ({ buttonClass }) => {
  const [, dispatch] = useStateValue();
  const app = useContext(AppContext);
  const [isDrawer, setDrawer] = useState(false);
  const toggleModal = () => {
    if (app) {
      setDrawer(!isDrawer)
    }
    else {
      dispatch(
        OpenModal({
          type: modalTypes.SLIDEIN,
          content: <OrderAddMissingPoints />,
        })
      );
    }
  };

  return (
    <>
    <button
      className={buttonClass}
      onClick={toggleModal}
      onKeyPress={redirectKeyPressHandler(toggleModal)}
    >
      {locale.buttons.addPoints}
    </button>
      <NavigationDrawer header={'Points'} drawerStatus={isDrawer} onClose={setDrawer}>
        <OrderAddMissingPoints />
      </NavigationDrawer>
    </>
  );
};

export default AddMissingPoints;
