// Libs.
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
// Custom Libs.
import navigate from '../../libs/navigate';
import { addNotification } from '../../libs/notify';
// Components.
import ErrorMessages from '../message/error-messages';
// State.
import { useStateValue } from '../../store/state';
import { CloseModal } from '../../store/modal/action-creators';
import { uiBodyScrollable } from '../../store/ui/action-creators';
// Services.
import crmClient from '../../services/crmClientService';
// Custom Libs.
import { redirectKeyPressHandler } from '../../libs/accessibility';
// Assets.
import { fmkOrderHistoryCancelSlider as locale } from '../../locale/locale.json';
import styles from './fmk-order-cancel.module.scss';
import pageAliases from '../../data/pageAliases.json';
import ButtonLoader from '../common/loaders/button-loader';

const FMKOrderItemCancel = ({ orderId }) => {
  const [state, dispatch] = useStateValue();
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const { EmailAddress } = state.customer;
  const { CRMCustomerID, access_token } = state.user;
  const closeModal = () => {
    // Set active if callback was provided.
    dispatch(CloseModal());
    dispatch(uiBodyScrollable());
  };

  const backToListing = () => {
    // Set active if callback was provided.
    dispatch(CloseModal());
    dispatch(uiBodyScrollable());
    navigate(pageAliases.groceriesTransactionList);
  };

  const handleCancelItem = (e) => {
    // Prevent cancel duplicated calls.
    if (loading) {
      return false;
    }

    // Flag cancel is processing.
    setLoading(true);
    crmClient
      .customerFMKOrderDelete(CRMCustomerID, orderId, access_token)
      .then((response) => {
        // Close modal.
        closeModal();
        // On success, send users back to the listing vs when the modal closes.
        navigate(pageAliases.groceriesTransactionList);
        addNotification(locale.messages.success);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = [error.message];
        setErrorMessages(errorMessage);
        // Remove message after some seconds.
        setTimeout(function() {
          setErrorMessages([]);
        }, 3000);
      });
  };

  return (
    <div className={styles.orderItemCancelOverlay}>
      <div className={styles.orderItemCancelContainer}>
        <span
          role="button"
          tabIndex="0"
          onClick={closeModal}
          onKeyPress={redirectKeyPressHandler(closeModal)}
          className={styles.orderItemCancelClose}
        />
        <h1 className={styles.orderItemCancelTitle}>{locale.titles.primary}</h1>
        <p className={styles.orderItemCancelConfirmMessage}>{locale.messages.confirm}</p>
        <div>
          <h2 className={styles.orderItemCancelOrderId}>{`${locale.orderLabel} ${orderId}`}</h2>
          <p className={styles.orderItemCancelMessage}>{locale.messages.refund}</p>
        </div>
        <div className={styles.orderItemCancelActions}>
          {errorMessages.length > 0 && <ErrorMessages messages={errorMessages} />}
          <button onClick={handleCancelItem} className={styles.orderItemCancelButtonConfirm}>
            {locale.buttons.confirm}
            {loading && <ButtonLoader />}
          </button>
          <button onClick={backToListing} className={styles.orderItemCancelButtonBack}>
            {locale.buttons.back}
          </button>
        </div>
      </div>
    </div>
  );
};

FMKOrderItemCancel.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default FMKOrderItemCancel;
