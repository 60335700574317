import React from 'react';
import calendarIcon from '../../../images/icons/expand.svg';

const CalendarIcon = () => {
  return (
    <>
      <img src={calendarIcon} alt="Calendar Icon" />
    </>
  );
};
export default CalendarIcon;
